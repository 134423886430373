import { css } from "@emotion/css";
import { ReactNode } from 'react';
import { QuizDefinition } from "src/quiz";
import { SubmissionState } from "src/ui/SubmissionState";
import { QuizSummary } from "./QuizSummary";
import { QuizView } from "./QuizView";

export function QuizSubmissionView({
  quiz,
  submissionState,
  sharedData,
  showSolutions,
  panel,
  summaryPanel,
}: {
  quiz: QuizDefinition;
  submissionState: SubmissionState;
  sharedData: Record<string, string>;
  showSolutions: boolean;
  panel: ReactNode;
  summaryPanel?: ReactNode;
}) {
  return (
    <div>
      <QuizSummary submissionState={submissionState} panel={summaryPanel} />

      <form
        onSubmit={(event) => {
          event.preventDefault();
          submissionState.updateSubmission(submissionState.answers);
        }}
      >
        <QuizView
          quiz={quiz}
          sharedData={sharedData}
          showSolutions={showSolutions}
          answers={submissionState.answers}
          submittedAnswers={submissionState.submittedAnswers}
          setAnswers={submissionState.setAnswers}
          editable={submissionState.canSubmit && !showSolutions}
        />

        <div
          className={css`
            display: flex;
            flex-flow: row wrap;
            position: sticky;
            bottom: 0;
            z-index: 1;
            width: fit-content;
            margin-left: auto;
            padding: 1rem 1rem 2rem;
            justify-content: flex-end;
            gap: 1rem;
          `}
        >
          {panel}
        </div>
      </form>
    </div>
  );
}
