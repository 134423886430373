// Alphanumeric characters except (similar-looking) "1ijl", "0o", and (uncommon in Italian) "kwxy".

export const passphraseAlphabet = Array.from("abcdefghmnpqrstuvz23456789");
export const passphrasePattern = "xxxx-xxxx-xxxx-xxxx";

export const passphrasePlaceholder = "x";
export const passphraseSeparator = "-";

export const passphraseParts = passphrasePattern
  .split(passphraseSeparator)
  .reduce((parts: Array<{ start: number; length: number; end: number }>, segment) => {
    const last = parts[parts.length - 1];
    const start = last ? last.end + 1 : 0;
    const length = segment.length;
    const end = start + length;
    return [...parts, { start, length, end }];
  }, []);

export const passphraseSeparatorIndexes = Array.from(passphrasePattern)
  .map((char, index) => ({ char, index }))
  .filter(({ char }) => char === passphraseSeparator)
  .map(({ index }) => index);
