import { SubmissionAnswerSet } from "src/db";
import { ProblemPrompt, QuizDefinition } from "src/quiz";
import { buildProblemSharedDataId } from "src/shared-data";
import { unreachable } from "src/util";

export const scorePerQuestion = 5;

export function createAllBlankAnswerSet(quiz: QuizDefinition) {
  return Object.fromEntries(
    quiz.sections.flatMap((section) => section.problems.map((problem) => [problem.id, problem.questions.map(() => "")]))
  );
}

export function totalScore(quiz: QuizDefinition) {
  return (
    quiz.sections.flatMap((section) => section.problems).flatMap((problem) => problem.questions).length *
    scorePerQuestion
  );
}

export function score(quiz: QuizDefinition, sharedData: Record<string, string>, submittedAnswers: SubmissionAnswerSet) {
  return quiz.sections
    .flatMap((section) => section.problems)
    .flatMap((problem) => {
      const problemData = JSON.parse(
        sharedData[buildProblemSharedDataId(problem.id)] ?? unreachable()
      ) as ProblemPrompt;

      return problem.questions.map((questionRef, questionIndex): number => {
        const question = problemData.questions[questionIndex];
        const answer = submittedAnswers?.[problem.id][questionIndex];
        switch (question.type) {
          case "open":
            return answer === question.solution!.correctAnswer ? 5 : 0;
          case "multiple-choice":
            return answer === question.solution!.correctOptionKey ? 5 : answer === "" ? 1 : 0;
        }
      });
    })
    .reduce((a, b) => a + b, 0);
}
