import { DateTime } from "luxon";
import { useCurrentMinute } from "src/ui/useCurrentMinute";

export function SubmissionTimeView({ value }: { value: Date }) {
  const now = useCurrentMinute();
  const minutesAgo = now.diff(DateTime.fromJSDate(value)).as("minutes");

  return (
    <>
      Ultimo salvataggio alle {new Intl.DateTimeFormat(undefined, { timeStyle: "medium" }).format(value)} (
      {minutesAgo > 0.5 ? <>circa {minutesAgo.toFixed()} minuti fa</> : <>pochi secondi fa</>})
    </>
  );
}
