import { GroupData } from "src/db";
import { computeGroupEndTime } from "./computeGroupEndTime";
import { GroupStartTimeDisplay } from "./GroupStartTimeDisplay";
import { parseConfigTime } from "./parseConfigTime";
import { useCurrentMinute } from "./useCurrentMinute";

export function GroupStatus({ groupData }: { groupData: GroupData }) {
  const now = useCurrentMinute();
  const endTime = computeGroupEndTime(groupData);

  return (
    <>
      {groupData.endedAt ? (
        <>Gara terminata</>
      ) : endTime ? (
        <>Gara in corso. Termina tra circa {endTime.diff(now).as("minutes").toFixed()} minuti.</>
      ) : groupData.startTime ? (
        groupData.autoStart ? (
          <>
            La gara inizierà automaticamente alle <GroupStartTimeDisplay time={parseConfigTime(groupData.startTime)} />{" "}
          </>
        ) : (
          <>
            Orario di inizio previsto: <GroupStartTimeDisplay time={parseConfigTime(groupData.startTime)} />. Inizio
            automatico NON impostato.
          </>
        )
      ) : (
        <>Orario di inizio non impostato.</>
      )}
    </>
  );
}
