import { doc, DocumentReference } from "@firebase/firestore";
import { ReactNode, useMemo } from "react";
import { HeaderData, ParticipantData } from "src/db";
import { useDocumentSnapshot, useSnapshotData } from "src/firebase-util";
import { BlockContent } from "src/quiz";
import { useSiteDoc } from "src/ui/getSite";

export function HeaderDataLoader({
  participantData,
  children,
}: {
  participantData: ParticipantData;
  children: (data: BlockContent) => ReactNode;
}) {
  const siteDoc = useSiteDoc();
  const document = useMemo(
    () => doc(siteDoc, "shared", participantData.headerId) as DocumentReference<HeaderData>,
    [participantData.headerId, siteDoc]
  );

  const data = useSnapshotData(useDocumentSnapshot(document));
  if (data === null) return <>Loading header data...</>;

  return <>{children(JSON.parse(data.json) as BlockContent)}</>;
}
