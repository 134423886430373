import { doc, DocumentReference, getFirestore, serverTimestamp, writeBatch } from "@firebase/firestore";
import { DateTime } from "luxon";
import { GroupData } from "src/db";

export async function updateGroup({
  siteDoc,
  group,
  updates,
  origin,
}: {
  siteDoc: DocumentReference;
  group: GroupData;
  updates: Partial<GroupData>;
  origin: "admin" | "proctor";
}) {
  const type = "update-group";

  await writeBatch(getFirestore())
    .set(doc(siteDoc, "groups", group.groupId), updates, { merge: true })
    .set(doc(siteDoc, "events", `${type}-${group.groupId}-${DateTime.utc().toISO()}`), {
      groupId: group.groupId,
      type,
      updates,
      origin,
      time: serverTimestamp(),
    })
    .commit();
}
