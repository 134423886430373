import { useCallback, useEffect, useState } from "react";
import { unreachable } from "src/util";

export type AsyncTaskState<T> =
  | { status: "pending"; clear: () => void }
  | { status: "fulfilled"; result: T; clear: () => void }
  | { status: "rejected"; error: unknown; clear: () => void }
  | null;
export type AsyncTaskFactory<T> = (signal: AbortSignal) => Promise<T>;

export function useAsyncTaskState<T>() {
  const [state, setState] = useState<AsyncTaskState<T>>(null);

  const run = useCallback((factory: AsyncTaskFactory<T>) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const clear = () => {
      controller.abort();
      setState(null);
    };

    setState((previous) => {
      if (previous !== null) unreachable(`running a task before clearing the previous one`);
      return { status: "pending", clear };
    });

    factory(signal)
      .then((result) => {
        if (controller.signal.aborted) return;
        setState({ status: "fulfilled", result, clear });
      })
      .catch((error) => {
        if (controller.signal.aborted) return;
        console.warn(error);
        setState({ status: "rejected", error, clear });
      });

    return clear;
  }, []);

  return { state, run };
}

export function useAsync<T>(factory: AsyncTaskFactory<T>) {
  const { state, run } = useAsyncTaskState<T>();

  useEffect(() => run(factory), [factory, run]);

  return state;
}
