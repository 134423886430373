import { css } from "@emotion/css";
import { BlockContent } from "src/quiz";

const commonBlockContentStyle = css`
  * {
    line-height: 140%;
  }

  p {
    margin: 0;
  }

  .two-columns {
    @media print {
      columns: 2;
      column-gap: 1.5cm;
      column-rule: solid 1pt black;
    }
  }

  img {
    display: block;
    margin: 2rem auto;
    max-width: 100%;
    object-fit: contain;
    break-inside: avoid;
  }

  code {
    font-family: monospace;
    font-size: 1rem;

    @media screen {
      color: #072063;
    }
  }

  .math.math-display {
    @media print {
      & > .katex-display > .katex {
        white-space: normal;

        & > .katex-html > .base {
          white-space: normal;
          width: auto;
        }
      }
    }

    @media screen {
      margin: 0 -1rem;
      padding: 0.5rem 2rem;
      overflow-x: auto;

      & > .katex-display {
        width: max-content;
      }
    }
  }

  .srs-syntax.block {
    display: block;
    border-left: 2px solid #8ea8ae;
    padding-left: 2rem;
    margin-left: 0.375rem;
  }

  code.block-code {
    display: block;
    break-inside: avoid;

    @media screen {
      white-space: nowrap;
      overflow-x: auto;
    }
  }

  code.block-code .srs-syntax.line {
    display: block;
    padding-left: 6rem;
    text-indent: -6rem;

    @media screen {
      border-radius: 0.25rem;
      padding-left: 6.25rem;
      padding-right: 0.25rem;

      &:hover {
        background-color: #0001;
      }
    }
  }

  .srs-syntax.keyword {
    font-weight: bold;
  }

  .srs-syntax.var {
    @media screen {
      color: #237ec8;
    }
    font-weight: bold;
  }

  .srs-syntax.var.highlighted {
    @media screen {
      background-color: #7fc9db;
    }
  }

  .srs-syntax.value {
    @media screen {
      color: #ab8300;
    }
  }

  .srs-syntax.operator {
    font-weight: bold;
    @media screen {
      color: #77488a;
    }
  }

  .srs-syntax.comment {
    @media screen {
      color: #3d8a05;
    }
  }

  .srs-syntax.type {
    font-style: italic;
  }

  .srs-syntax.expression {
    @media screen {
      color: #851e30;
    }
  }

  .srs-syntax.body {
    font-weight: bold;
    @media screen {
      color: #d47c2a;
    }
  }

  .srs-syntax.alert {
    @media screen {
      padding: 0.15rem 0.8rem;
      border-radius: 0.25rem;
      color: #fff;
      background-color: #d84d4d;
    }
  }

  .srs-syntax.active-expr {
    text-decoration: underline;
  }

  .srs-syntax.active-stmt {
    @media screen {
      background-color: #073642;
    }
  }
`;

export function BlockContentView({ value }: { value: BlockContent }) {
  return (
    <div
      className={css`
        ${commonBlockContentStyle}

        p {
          margin: 0.5rem 0;
        }

        code.block-code {
          margin: 1.25rem auto;

          @media screen {
            padding: 0 1.5rem;
          }
        }
      `}
      dangerouslySetInnerHTML={{ __html: value.content }}
    />
  );
}

export function InlineBlockContentView({ value }: { value: BlockContent }) {
  return (
    <div
      className={css`
        ${commonBlockContentStyle}

        flex: 1;

        code.block-code {
          padding: 0.25rem 0.5rem;
        }
      `}
      dangerouslySetInnerHTML={{ __html: value.content }}
    />
  );
}
