import { DateTime } from "luxon";
import { useCurrentMinute } from "./useCurrentMinute";

export function GroupStartTimeDisplay({ time }: { time: DateTime }) {
  const now = useCurrentMinute();
  const diffMinutes = time.diff(now).as("minutes");

  return (
    <>
      {time.toLocaleString({ timeStyle: "short" })}{" "}
      {diffMinutes < 180 ? diffMinutes > 0 ? <>(fra circa {diffMinutes} minuti)</> : <>(appena possibile)</> : <></>}
    </>
  );
}
