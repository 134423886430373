import { css } from "@emotion/css";
import { DocumentReference } from "@firebase/firestore";
import { useEffect, useRef } from "react";
import { useDocumentSnapshot, useSnapshotData } from "src/firebase-util";
import { useSiteDoc } from "src/ui/getSite";

export type AnnouncementTarget = "allProctorsMessage" | "allParticipantsMessage";
export type AnnouncementData = Partial<Record<AnnouncementTarget, string>>;

export function Announcement({ which }: { which: AnnouncementTarget }) {
  const siteDoc = useSiteDoc() as DocumentReference<AnnouncementData>;
  const snapshot = useDocumentSnapshot(siteDoc);
  const data = useSnapshotData(snapshot);
  const message = data?.[which];

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (message) ref.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }, [message]);

  if (typeof message !== "string") return null;
  if (!message) return null;

  return (
    <div
      className={css`
        padding: 0.75rem 1rem;
        border: 2px solid red;
        border-radius: 0.5rem;
      `}
      ref={ref}
    >
      {message}
    </div>
  );
}
