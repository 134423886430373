import { css } from "@emotion/css";
import { SubmissionState } from "src/ui/SubmissionState";
import { answerColorByStatus } from "./AnswerStatus";
import { buttonCss } from "./buttonCss";

export function QuizSubmitPanel({ submissionState }: { submissionState: SubmissionState }) {
  if (!submissionState.canSubmit) return null;

  return (
    <button
      type="submit"
      disabled={submissionState.status === "pristine" || submissionState.status === "blank"}
      className={css`
        background-color: ${answerColorByStatus.saved};
        ${buttonCss}
      `}
    >
      {submissionState.status === "blank" && <>Nessuna risposta</>}
      {submissionState.status === "pristine" && <>Risposte salvate</>}
      {submissionState.status === "pending" && <>Salvataggio...</>}
      {submissionState.status === "dirty" && <>Salva risposte</>}
    </button>
  );
}
