import { doc, DocumentReference, getFirestore, serverTimestamp, writeBatch } from "@firebase/firestore";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { GroupData } from "src/db";
import { computeGroupEndTime } from "src/ui/computeGroupEndTime";
import { useSiteDoc } from "src/ui/getSite";
import { useCurrentMinute } from "src/ui/useCurrentMinute";

export function AutoStartStopGroups({ groups }: { groups: GroupData[] }) {
  const siteDoc = useSiteDoc();
  const now = useCurrentMinute();

  useEffect(() => {
    for (const group of groups) {
      if (group.participantsCanReadQuiz) continue;
      if (!group.startTime) continue;
      if (!group.autoStart) continue;
      if (now < DateTime.fromISO(group.startTime)) continue;

      const type = `auto-start-group`;
      writeBatch(getFirestore())
        .set(
          doc(siteDoc, "groups", group.groupId) as DocumentReference<GroupData>,
          { participantsCanReadQuiz: true, participantsCanSubmit: true, startedAt: serverTimestamp() },
          { merge: true }
        )
        .set(doc(siteDoc, "events", `${type}-${group.groupId}-${DateTime.utc().toISO()}`), {
          groupId: group.groupId,
          type,
          origin: "admin",
          time: serverTimestamp(),
        })
        .commit();
    }
  }, [groups, now, siteDoc]);

  useEffect(() => {
    for (const group of groups) {
      if (!group.participantsCanSubmit) continue;
      const endTime = computeGroupEndTime(group);
      if (endTime === null) continue;
      if (endTime > now) continue;

      const type = `auto-stop-group`;
      writeBatch(getFirestore())
        .set(
          doc(siteDoc, "groups", group.groupId) as DocumentReference<GroupData>,
          { participantsCanReadQuiz: true, participantsCanSubmit: false, endedAt: serverTimestamp() },
          { merge: true }
        )
        .set(doc(siteDoc, "events", `${type}-${group.groupId}-${DateTime.utc().toISO()}`), {
          groupId: group.groupId,
          type,
          origin: "admin",
          time: serverTimestamp(),
        })
        .commit();
    }
  }, [groups, now, siteDoc]);

  return null;
}
