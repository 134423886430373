import { CurrentUserLoader } from "src/ui/CurrentUserLoader";
import { LoginForm } from "src/ui/LoginForm";
import { RootLayout } from "src/ui/RootLayout";
import { CurrentTimeProvider } from "src/ui/useCurrentMinute";
import { UserView } from "src/ui/UserView";

export function ContestApp(): JSX.Element {
  return (
    <CurrentTimeProvider>
      <CurrentUserLoader>
        <RootLayout>
          <LoginForm />
          <UserView />
        </RootLayout>
      </CurrentUserLoader>
    </CurrentTimeProvider>
  );
}
