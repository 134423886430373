import { AdminView } from "src/ui/AdminView";
import { useCurrentContestUser } from "src/ui/CurrentUserLoader";
import { GroupDataLoader } from "src/ui/GroupDataLoader";
import { ParticipantView } from "src/ui/ParticipantView";
import { ProctorView } from "src/ui/ProctorView";

export function UserView() {
  const user = useCurrentContestUser();

  if (!user) return null;

  switch (user.type) {
    case "participant": {
      const { groupId, particpantId } = user;
      return <ParticipantView groupId={groupId} participantId={particpantId} />;
    }
    case "proctor": {
      const { groupId } = user;
      return (
        <GroupDataLoader groupId={groupId}>
          {(data, update) => <ProctorView groupId={groupId} groupData={data} groupUpdate={update} />}
        </GroupDataLoader>
      );
    }
    case "admin":
      return <AdminView />;
    case "unknown":
      return <>Unknown user</>;
  }
}
