import { ReactNode } from "react";
import { QuizDefinition } from "src/quiz";
import { SubmissionState, SubmissionStateManager } from "src/ui/SubmissionState";
import { SubmissionSource, SubmissionStateLoader } from "src/ui/SubmissionStateLoader";

export function SubmissionStateFullLoader({
  compositeUserId,
  canSubmit,
  quiz,
  source,
  children,
}: {
  compositeUserId: string;
  canSubmit: boolean;
  quiz: QuizDefinition;
  source: SubmissionSource;
  children: (submissionState: SubmissionState) => ReactNode;
}) {
  return (
    <SubmissionStateLoader collectionName="submissions" compositeUserId={compositeUserId} source={source}>
      {(submission, updateSubmission, submissionMetadata) => (
        <SubmissionStateLoader collectionName="autosave" compositeUserId={compositeUserId} source={source}>
          {(autosave, updateAutosave) => (
            <SubmissionStateManager
              submission={submission}
              updateSubmission={updateSubmission}
              submissionMetadata={submissionMetadata}
              autosave={autosave}
              updateAutosave={updateAutosave}
              canSubmit={canSubmit}
              quiz={quiz}
            >
              {children}
            </SubmissionStateManager>
          )}
        </SubmissionStateLoader>
      )}
    </SubmissionStateLoader>
  );
}
