import { Bytes, doc, DocumentReference } from "@firebase/firestore";
import { ReactNode, useMemo } from "react";
import { ParticipantData } from "src/db";
import { useDocumentSnapshots } from "src/firebase-util";
import { useSiteDoc } from "src/ui/getSite";
import { unreachable } from "src/util";

export function EncryptedDataFetcher({
  participantData,
  children,
}: {
  participantData: ParticipantData;
  children: (encryptedData: Record<string, Uint8Array>) => ReactNode;
}) {
  const siteDoc = useSiteDoc();

  const sharedDataSnapshots = useDocumentSnapshots(
    useMemo(() => {
      return participantData.prefetchIds.map(
        (id) => doc(siteDoc, "encrypted", id) as DocumentReference<{ cipherText: Bytes }>
      );
    }, [participantData.prefetchIds, siteDoc])
  );

  const encryptedData = useMemo(() => {
    if (sharedDataSnapshots.some((x) => x === null)) return null;

    return Object.fromEntries(
      sharedDataSnapshots
        .map((item) => item ?? unreachable())
        .map((item) => [item.id, (item.data() ?? unreachable()).cipherText.toUint8Array()])
    );
  }, [sharedDataSnapshots]);

  if (encryptedData === null) return <>Fetching encrypted data...</>;

  return <>{children(encryptedData)}</>;
}
