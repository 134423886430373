import { getDownloadURL, getStorage, ref } from "@firebase/storage";
import { ReactNode, useCallback } from "react";
import { useAsync } from "src/react-util";

export function DownloadLink({ path, children }: { path: string; children: ReactNode }) {
  const task = useAsync(useCallback(async () => getDownloadURL(ref(getStorage(), path)), [path]));

  if (task?.status === "fulfilled") {
    return (
      <a href={task.result} download target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return <>PDF non ancora disponibile</>;
}
