import { ivLength, keyLength } from "src/crypto-config";

const allZerosIV = Uint8Array.from({ length: ivLength }, () => 0);

export async function decryptPayload(cipherText: Uint8Array, keyHex: string) {
  const keyRaw = Uint8Array.from({ length: keyLength }, (unused, i) => parseInt(keyHex.slice(2 * i, 2 * (i + 1)), 16));
  const key = await crypto.subtle.importKey("raw", keyRaw, "AES-CBC", true, ["encrypt", "decrypt"]);

  return crypto.subtle.decrypt({ name: "AES-CBC", iv: allZerosIV }, key, cipherText);
}
