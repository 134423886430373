import {
  doc,
  DocumentReference,
  getFirestore,
  serverTimestamp,
  SnapshotMetadata,
  writeBatch,
} from "@firebase/firestore";
import { DateTime } from "luxon";
import { ReactNode, useCallback, useMemo } from "react";
import { BaseGroupData, BaseParticipantData, SubmissionAnswerSet, SubmissionData } from "src/db";
import { useDocumentSnapshot } from "src/firebase-util";
import { useSiteDoc } from "src/ui/getSite";

export type SubmissionUpdate = (answers: SubmissionAnswerSet) => Promise<void>;

export type SubmissionSource =
  | ({ origin: "participant" } & BaseParticipantData)
  | ({ origin: "proctor" } & BaseGroupData)
  | { origin: "admin" }
  | { origin: "anonymous" };

export function SubmissionStateLoader({
  collectionName,
  compositeUserId,
  children,
  source,
}: {
  collectionName: "submissions" | "autosave";
  compositeUserId: string;
  children: (data: SubmissionData, update: SubmissionUpdate, metadata: SnapshotMetadata) => ReactNode;
  source: SubmissionSource;
}) {
  const siteDoc = useSiteDoc();
  const document = useMemo(
    () => doc(siteDoc, collectionName, compositeUserId) as DocumentReference<SubmissionData>,
    [collectionName, compositeUserId, siteDoc]
  );

  const updateData = useCallback<SubmissionUpdate>(
    async (answers) => {
      const type = `update-participant-${collectionName}`;

      await writeBatch(getFirestore())
        .set(document, { time: serverTimestamp(), answers }, { merge: true })
        .set(doc(siteDoc, "events", `${type}-${compositeUserId}-${DateTime.utc().toISO()}`), {
          type,
          compositeUserId,
          ...source,
          answers,
          time: serverTimestamp(),
        })
        .commit();
    },
    [collectionName, compositeUserId, document, siteDoc, source]
  );

  const snapshot = useDocumentSnapshot(document, { includeMetadataChanges: true });

  if (!snapshot) return <>Loading...</>;

  const data = snapshot.data();
  return <>{children(data ?? { groupId: "", participantId: "" }, updateData, snapshot.metadata)}</>;
}
