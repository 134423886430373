import { QuestionDefinition, QuestionReference } from "src/quiz";
import { MultipleChoiceQuestion } from "src/ui/MultipleChoiceQuestion";
import { OpenQuestion } from "src/ui/OpenQuestion";
import { unreachable } from "src/util";

export function QuestionInner({
  name,
  question,
  questionRef,
  editable,
  value,
  savedValue,
  onChange,
  showSolutions,
}: {
  name: string;
  question: QuestionDefinition;
  questionRef: QuestionReference;
  editable: boolean;
  value: string;
  savedValue: string;
  onChange: (value: string) => void;
  showSolutions: boolean;
}) {
  switch (questionRef.inner.type) {
    case "open":
      if (question.type !== "open") unreachable(`question type mismatch`);
      return (
        <OpenQuestion
          name={name}
          question={question}
          questionRef={questionRef.inner}
          editable={editable}
          value={value}
          savedValue={savedValue}
          onChange={onChange}
          solution={showSolutions ? question.solution : null}
        />
      );
    case "multiple-choice":
      if (question.type !== "multiple-choice") unreachable(`question type mismatch`);
      return (
        <MultipleChoiceQuestion
          name={name}
          question={question}
          questionRef={questionRef.inner}
          editable={editable}
          value={value}
          savedValue={savedValue}
          onChange={onChange}
          solution={showSolutions ? question.solution : null}
        />
      );
  }
}
