import { css } from "@emotion/css";
import { collection, CollectionReference } from "@firebase/firestore";
import { useMemo } from "react";
import { uidProctorPrefix, uidSeparatorCharacter } from "src/common";
import { GroupData } from "src/db";
import { useQuerySnapshot } from "src/firebase-util";
import { useSite, useSiteDoc } from "src/ui/getSite";
import { GroupStatus } from "src/ui/GroupStatus";
import { AutoStartStopGroups } from "./AutoStartStopGroups";

export function AdminView() {
  const site = useSite();
  const siteDoc = useSiteDoc();

  const groupsCollection = useMemo(() => collection(siteDoc, "groups") as CollectionReference<GroupData>, [siteDoc]);
  const groupSnapshots = useQuerySnapshot(groupsCollection);

  if (!groupSnapshots) return <>Loading...</>;

  const groups = groupSnapshots.docs.map((snapshot) => snapshot.data());

  return (
    <>
      <AutoStartStopGroups groups={groups} />
      <a
        href={
          process.env.FIRESTORE_EMULATOR_HOST
            ? `http://localhost:4000/firestore/data/site/${site.name}`
            : `https://console.firebase.google.com/u/_/project/${
                process.env.FIREBASE_PROJECT_ID
              }/firestore/data/${encodeURIComponent(`/site/${site.name}`)}`
        }
        target="_blank"
        rel="noreferrer"
      >
        Firestore Console
      </a>
      {groups.map((group) => (
        <div
          key={group.groupId}
          className={css`
            display: flex;
            flex-flow: row wrap;
            gap: 0.5rem;
            padding: 0.5rem;
            align-items: baseline;

            &:nth-child(2n) {
              background-color: rgb(0 0 0 / 10%);
            }

            &:nth-child(2n + 1) {
              background-color: rgb(0 0 0 / 5%);
            }
          `}
        >
          <div
            className={css`
              flex: 1 1 auto;
            `}
          >
            <samp>{group.groupId}</samp> {group.info.displayName}
          </div>
          <div>
            <GroupStatus groupData={group} />
          </div>
          <div>
            <a
              href={`?impersonate=${[site.name, uidProctorPrefix, group.groupId].join(uidSeparatorCharacter)}`}
              target="_blank"
              rel="noreferrer"
            >
              Impersona
            </a>
          </div>
          <div>
            <a
              href={
                process.env.FIRESTORE_EMULATOR_HOST
                  ? `http://localhost:4000/firestore/data/site/${site.name}/groups/${group.groupId}`
                  : `https://console.firebase.google.com/u/_/project/${
                      process.env.FIREBASE_PROJECT_ID
                    }/firestore/data/${encodeURIComponent(`/site/${site.name}/groups/${group.groupId}`)}`
              }
              target="_blank"
              rel="noreferrer"
            >
              Firestore Console
            </a>
          </div>
        </div>
      ))}
    </>
  );
}
