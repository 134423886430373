import { css } from "@emotion/css";
import { useMemo, useState } from "react";
import Modal from "react-modal";
import { uidSeparatorCharacter } from "src/common";
import { GroupData, ParticipantData } from "src/db";
import { Decrypter } from "src/ui/Decrypter";
import { EncryptedDataFetcher } from "src/ui/EncryptedDataFetcher";
import { EnvelopeCutterLoader } from "src/ui/EnvelopeCutterLoader";
import { loadQuiz } from "src/ui/loadQuiz";
import { ParticipantDataLoader } from "src/ui/ParticipantDataLoader";
import { ParticipantQuizView } from "src/ui/ParticipantQuizView";
import { SubmissionStateFullLoader } from "src/ui/SubmissionStateFullLoader";
import { SubmissionSource } from "src/ui/SubmissionStateLoader";

export function ProctorSubmitForParticipantButton({
  participant,
  group,
}: {
  participant: ParticipantData;
  group: GroupData;
}) {
  const participantId = participant.participantId;
  const groupId = participant.groupId;

  const [isOpen, setOpen] = useState(false);

  const source = useMemo((): SubmissionSource => ({ origin: "proctor", groupId }), [groupId]);

  return (
    <>
      <button
        onClick={() => {
          setOpen(true);
        }}
      >
        Modifica risposte
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setOpen(false);
        }}
        htmlOpenClassName={css`
          overflow: hidden !important;
          padding-right: 1rem;
        `}
        overlayClassName={css`
          position: fixed;
          inset: 0;
          bottom: auto;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          height: 100%;
          background-color: rgb(127 127 127 / 50%);
          backdrop-filter: blur(1rem) grayscale(0.8);
          overflow-y: scroll;
        `}
        className={css`
          display: flex;
          flex-flow: column;
          width: 100%;
          max-width: 60rem;
          margin: 4rem 1rem;
          pointer-events: none;

          & > * {
            pointer-events: visible;
          }
        `}
      >
        <button
          className={css`
            align-self: flex-end;
            margin: 1rem;
            padding: 1rem 1.5rem;
          `}
          onClick={() => {
            setOpen(false);
          }}
        >
          Chiudi
        </button>
        <div
          className={css`
            background-color: white;
            border-radius: 0.5rem;
            padding: 1.5rem 1rem;
            box-shadow: 0 0 1rem 0 rgb(0 0 0 / 15%);
          `}
        >
          <div>
            <h3>{participant.info.displayName}</h3>
            <div>
              <ParticipantDataLoader groupId={groupId} participantId={participant.participantId}>
                {(participantData) => (
                  <EncryptedDataFetcher participantData={participantData}>
                    {(encryptedData) => (
                      <EnvelopeCutterLoader groupId={groupId} participantId={participant.participantId}>
                        {(envelopeCutter) => (
                          <Decrypter encryptedData={encryptedData} envelopeCutter={envelopeCutter}>
                            {(sharedData) => {
                              const quiz = loadQuiz(sharedData, groupId, participantId);
                              return (
                                <SubmissionStateFullLoader
                                  compositeUserId={[groupId, participantId].join(uidSeparatorCharacter)}
                                  canSubmit={true}
                                  quiz={quiz}
                                  source={source}
                                >
                                  {(submissionState) => (
                                    <ParticipantQuizView
                                      quiz={quiz}
                                      groupData={group}
                                      sharedData={sharedData}
                                      submissionState={submissionState}
                                    />
                                  )}
                                </SubmissionStateFullLoader>
                              );
                            }}
                          </Decrypter>
                        )}
                      </EnvelopeCutterLoader>
                    )}
                  </EncryptedDataFetcher>
                )}
              </ParticipantDataLoader>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
