import { doc, DocumentReference, getFirestore } from "@firebase/firestore";
import { createContext, ReactNode, useContext, useMemo } from "react";
import { unreachable } from "src/util";

export interface Site {
  name: string;
  isDemo: boolean;
}

interface SiteContextData {
  site: Site;
  doc: DocumentReference;
}

const context = createContext<SiteContextData | null>(null);

export function SiteProvider({ site: { isDemo, name }, children }: { site: Site; children: ReactNode }) {
  const value = useMemo(
    () => ({
      site: { isDemo, name },
      doc: isDemo ? doc(getFirestore(), "demo-site", name) : doc(getFirestore(), "site", name),
    }),
    [isDemo, name]
  );

  return <context.Provider value={value}>{children}</context.Provider>;
}

export function useSite() {
  const data = useContext(context);
  if (!data) unreachable(`BUG: site not provided`);
  return data.site;
}

export function useSiteDoc() {
  const data = useContext(context);
  if (!data) unreachable(`BUG: site not provided`);
  return data.doc;
}
