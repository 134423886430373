import { css } from "@emotion/css";
import { useEffect, useRef } from "react";
import { GroupData } from "src/db";
import { QuizDefinition } from "src/quiz";
import { computeGroupEndTime } from "src/ui/computeGroupEndTime";
import { SubmissionState } from "src/ui/SubmissionState";
import { useCurrentMinute } from "src/ui/useCurrentMinute";
import { QuizSubmissionView } from "./QuizSubmissionView";
import { QuizSubmitPanel } from "./QuizSubmitPanel";

export function ParticipantQuizView({
  quiz,
  sharedData,
  submissionState,
  groupData,
}: {
  quiz: QuizDefinition;
  sharedData: Record<string, string>;
  submissionState: SubmissionState;
  groupData: GroupData;
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <div
        ref={ref}
        className={css`
          display: none;

          @media screen {
            display: unset;
          }
        `}
      ></div>
      <QuizSubmissionView
        quiz={quiz}
        submissionState={submissionState}
        sharedData={sharedData}
        showSolutions={false}
        panel={<QuizSubmitPanel submissionState={submissionState} />}
        summaryPanel={<ParticipantContestTimer groupData={groupData} />}
      />
    </>
  );
}

export function ParticipantContestTimer({ groupData }: { groupData: GroupData }) {
  const endTime = computeGroupEndTime(groupData);
  const now = useCurrentMinute();

  if (!groupData.participantsCanSubmit) return <>Gara terminata.</>;

  if (endTime === null) return null; // Should never occur

  const endsInMinutes = endTime.diff(now).as("minutes");
  return (
    <>Gara in corso. Termina {endsInMinutes > 1 ? <>fra circa {endsInMinutes.toFixed()} minuti</> : <>a breve</>}.</>
  );
}
