import { Timestamp } from "@firebase/firestore";
import { DateTime } from "luxon";
import { GroupData } from "src/db";

export function computeGroupEndTime(groupData: GroupData) {
  if (!groupData.startedAt) return null;

  return DateTime.fromJSDate((groupData.startedAt as Timestamp).toDate()).plus({
    minutes: groupData.durationMinutes,
  });
}
