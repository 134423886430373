import { Dispatch, SetStateAction } from "react";
import { SubmissionAnswerSet } from "src/db";
import { QuizDefinition } from "src/quiz";
import { createAllBlankAnswerSet } from "src/ui/answer-utils";
import { Problem } from "src/ui/Problem";
import { QuizSection } from "src/ui/QuizSection";
import { QuizProblemSetView } from "./QuizProblemSetView";

export function QuizView({
  quiz,
  sharedData,
  showSolutions = false,
  answers = createAllBlankAnswerSet(quiz),
  submittedAnswers = null,
  setAnswers = () => {
    /* Ignore */
  },
  editable = false,
}: {
  quiz: QuizDefinition;
  sharedData: Record<string, string>;
  showSolutions?: boolean;
  answers?: SubmissionAnswerSet;
  submittedAnswers?: SubmissionAnswerSet | null;
  setAnswers?: Dispatch<SetStateAction<SubmissionAnswerSet>>;
  editable?: boolean;
}) {
  return (
    <QuizProblemSetView
      quiz={quiz}
      renderSection={(section) => (
        <QuizSection
          section={section}
          renderProblem={(problemRef) => (
            <Problem
              problemRef={problemRef}
              sharedData={sharedData}
              editable={editable}
              answers={answers[problemRef.id]}
              savedAnswers={problemRef.questions.map(
                (question, questionIndex) => submittedAnswers?.[problemRef.id][questionIndex] ?? ""
              )}
              onChange={(questionIndex, value) => {
                setAnswers((oldAnswers) => ({
                  ...oldAnswers,
                  [problemRef.id]: oldAnswers[problemRef.id].map((oldValue, i) =>
                    i === questionIndex ? value : oldValue
                  ),
                }));
              }}
              showSolutions={showSolutions}
            />
          )}
        />
      )}
    />
  );
}
