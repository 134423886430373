import { css } from "@emotion/css";
import { MultipleChoiceQuestionSolution, OpenQuestionSolution } from "src/quiz";
import { BlockContentView } from "src/ui/BlockContentView";

export function Solution({ solution }: { solution: OpenQuestionSolution | MultipleChoiceQuestionSolution }) {
  return (
    <details>
      <summary
        className={css`
          font-size: 0.875rem;
          padding: 0 0.75rem;
        `}
      >
        Spiegazione
      </summary>
      <div
        className={css`
          background-color: white;
          margin: 0.5rem;
          padding: 0.75rem 1rem;
          border-radius: 0.5rem;
        `}
      >
        <BlockContentView value={solution.explanation} />
      </div>
    </details>
  );
}
