import { ReactNode, useCallback } from "react";
import { decryptPayload } from "src/crypto-util-web";
import { EnvelopeCutter } from "src/db";
import { useAsync } from "src/react-util";

export function Decrypter({
  envelopeCutter,
  encryptedData,
  children,
}: {
  envelopeCutter: EnvelopeCutter;
  encryptedData: Record<string, Uint8Array>;
  children: (decryptedData: Record<string, string>) => ReactNode;
}) {
  const decryptionResult = useAsync(
    useCallback(async () => {
      return Object.fromEntries(
        await Promise.all(
          Object.entries(envelopeCutter).map(async ([id, { cipherId, keyHex }]) => {
            const cipherText = encryptedData[cipherId];

            if (!cipherText) throw new Error(`cipher text not loaded`);

            const clearTextRaw = await decryptPayload(cipherText, keyHex);
            const clearText = new TextDecoder().decode(clearTextRaw);

            return [id, clearText] as const;
          })
        )
      );
    }, [encryptedData, envelopeCutter])
  );

  if (decryptionResult?.status === "rejected") return <>Error while decrypting: {String(decryptionResult.error)}</>;
  if (decryptionResult?.status !== "fulfilled") return <>Loading... (decrypting)</>;

  return <>{children(decryptionResult.result)}</>;
}
