import { createRoot } from "react-dom/client";
import { ContestApp } from "src/ui/ContestApp";
import { DemoApp } from "src/ui/DemoApp";
import { SiteProvider } from "src/ui/getSite";
import { PreviewQuizView } from "src/ui/PreviewQuizView";
import { unreachable } from "src/util";

const root = createRoot(document.getElementById("root") ?? unreachable());

if (process.env.PREVIEW === "1") {
  root.render(<PreviewQuizView />);
} else if (process.env.DEMO === "1") {
  root.render(
    <SiteProvider site={{ name: process.env.SITE ?? "default", isDemo: true }}>
      <DemoApp />
    </SiteProvider>
  );
} else {
  root.render(
    <SiteProvider site={{ name: process.env.SITE ?? "default", isDemo: false }}>
      <ContestApp />
    </SiteProvider>
  );
}
