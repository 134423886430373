import { initializeApp } from "@firebase/app";
import { connectAuthEmulator, getAuth } from "@firebase/auth";
import { connectFirestoreEmulator, enableMultiTabIndexedDbPersistence, getFirestore } from "@firebase/firestore";
import { connectStorageEmulator, getStorage } from "@firebase/storage";
import { firebaseConfig } from "./firebase-config";

export function initializeFirebase() {
  initializeApp(firebaseConfig);

  if (process.env.FIRESTORE_EMULATOR_HOST) {
    const [host, port] = process.env.FIRESTORE_EMULATOR_HOST.split(":");
    connectFirestoreEmulator(getFirestore(), host, parseInt(port));
  }

  if (process.env.FIREBASE_AUTH_EMULATOR_HOST) {
    connectAuthEmulator(getAuth(), `http://${process.env.FIREBASE_AUTH_EMULATOR_HOST}`, { disableWarnings: true });
  }

  if (process.env.FIREBASE_STORAGE_EMULATOR_HOST) {
    const [host, port] = process.env.FIREBASE_STORAGE_EMULATOR_HOST.split(":");
    connectStorageEmulator(getStorage(), host, parseInt(port));
  }

  enableMultiTabIndexedDbPersistence(getFirestore());
}
