import { DateTime } from "luxon";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { unreachable } from "src/util";

const context = createContext<DateTime | null>(null);

export function useCurrentMinute() {
  return useContext(context) ?? unreachable(`BUG: no CurrentTimeProvider`);
}

function getCurrentMinute(): DateTime {
  return DateTime.now().startOf("minute");
}

export function CurrentTimeProvider({ children }: { children: ReactNode }) {
  const [now, setNow] = useState(getCurrentMinute());

  useEffect(() => {
    const timeout = setTimeout(() => {
      setNow(getCurrentMinute());
    }, 30000);
    return () => {
      clearTimeout(timeout);
    };
  });

  return <context.Provider value={now}>{children}</context.Provider>;
}
