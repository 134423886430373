import { getAuth, onAuthStateChanged, User } from "@firebase/auth";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { uidAdminPrefix, uidParticipantPrefix, uidProctorPrefix, uidSeparatorCharacter } from "src/common";
import { useSite } from "src/ui/getSite";
import { unreachable } from "src/util";

const context = createContext<{ user: User | null } | null>(null);

export function CurrentUserLoader({ children }: { children: ReactNode }) {
  const [authState, setAuthState] = useState<{ user: User | null } | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      setAuthState({ user });
    });

    return () => {
      setAuthState(null);
      unsubscribe();
    };
  }, []);

  if (!authState) return <>Loading...</>;

  return <context.Provider value={authState}>{children}</context.Provider>;
}

export function useCurrentContestUser() {
  const user = useCurrentUser();
  const site = useSite();

  if (!user) return null;

  const impersonate = new URLSearchParams(window.location.search).get("impersonate");

  const uid = impersonate ?? user.uid;

  const expectedPrefix = `${site.name}${uidSeparatorCharacter}`;
  if (!uid.startsWith(expectedPrefix)) unreachable(`unexpected site mismatch`);

  const [prefix, ...rest] = uid.slice(expectedPrefix.length).split(uidSeparatorCharacter);

  switch (prefix) {
    case uidParticipantPrefix: {
      if (rest.length !== 2) unreachable(`invalid uid`);
      const [groupId, particpantId] = rest;
      return { type: "participant" as const, groupId, particpantId };
    }
    case uidProctorPrefix: {
      if (rest.length !== 1) unreachable(`invalid uid`);
      const [groupId] = rest;
      return { type: "proctor" as const, groupId };
    }
    case uidAdminPrefix:
      return { type: "admin" as const };
    default:
      return { type: "unknown" as const };
  }
}

export function useCurrentUser() {
  const { user } = useContext(context) ?? unreachable(`BUG: missing CurrentUserLoader`);
  return user;
}
