import { css } from "@emotion/css";
import { DateTime } from "luxon";
import { useId } from "react";
import { GroupData } from "src/db";
import { GroupDataUpdate } from "src/ui/GroupDataLoader";
import { computeGroupEndTime } from "./computeGroupEndTime";
import { GroupStartTimeDisplay } from "./GroupStartTimeDisplay";
import { parseConfigTime } from "./parseConfigTime";
import { useCurrentMinute } from "./useCurrentMinute";

export function GroupConfig({ groupData, groupUpdate }: { groupData: GroupData; groupUpdate: GroupDataUpdate }) {
  const endTime = computeGroupEndTime(groupData);

  return (
    <>
      {groupData.endedAt ? (
        <></>
      ) : endTime ? (
        <></>
      ) : groupData.autoStart && groupData.startTime ? (
        <>
          <button
            onClick={() => {
              groupUpdate({ autoStart: false });
            }}
          >
            Cambia orario o avvia manualmente
          </button>
        </>
      ) : (
        <div
          className={css`
            display: flex;
            flex-flow: row wrap;
            align-items: baseline;
            margin: 0.5rem 0;
            padding: 0.5rem;
            background-color: rgb(0 0 0 / 10%);
            border-radius: 0.5rem;
            gap: 1rem;
          `}
        >
          <div
            className={css`
              flex: 1 0 auto;
            `}
          >
            <GroupStartTimePicker groupData={groupData} groupUpdate={groupUpdate} />{" "}
          </div>
          {groupData.startTime && (
            <button
              onClick={() => {
                groupUpdate({ startTime: groupData.startTime, autoStart: true });
              }}
              className={css`
                padding: 0.5rem;
                font-size: 1.125rem;
              `}
            >
              <SetAutoStartButtonLabel time={parseConfigTime(groupData.startTime)} />
            </button>
          )}
        </div>
      )}
    </>
  );
}

function SetAutoStartButtonLabel({ time }: { time: DateTime }) {
  const now = useCurrentMinute();
  const inMinutes = time.diff(now).as("minutes");

  if (inMinutes > 10) {
    return (
      <>
        Imposta inizio automatico alle <GroupStartTimeDisplay time={time} />
      </>
    );
  }

  if (inMinutes > 1) return <>INIZIA GARA (fra circa {inMinutes.toFixed()} minuti)</>;

  return <>INIZIA GARA ORA</>;
}

function GroupStartTimePicker({ groupData, groupUpdate }: { groupData: GroupData; groupUpdate: GroupDataUpdate }) {
  const id = useId();

  return (
    <>
      <label htmlFor={`start-time-${id}`}>Scegli orario di inizio: </label>
      <select
        id={`start-time-${id}`}
        value={groupData.startTime}
        onChange={(event) => {
          groupUpdate({ startTime: event.currentTarget.value, autoStart: false });
        }}
        className={css`
          padding: 0.5rem;
          font-size: 1.125rem;
        `}
      >
        <option value="">Scegli...</option>
        {groupData.allowedStartTimes.map((value) => {
          const time = parseConfigTime(value);
          return (
            <option key={value} value={value}>
              {time.toLocaleString({ dateStyle: "short", timeStyle: "short" })}
            </option>
          );
        })}
      </select>
    </>
  );
}
