import { useEffect } from "react";
import { SubmissionAnswerSet } from "src/db";
import { QuizDefinition } from "src/quiz";
import { SubmissionUpdate } from "src/ui/SubmissionStateLoader";

export function useSyncAutoSave({
  answers,
  updateAutosave,
}: {
  quiz: QuizDefinition;
  answers: SubmissionAnswerSet;
  autosavedAnswers: SubmissionAnswerSet | null;
  setAnswers: (answers: SubmissionAnswerSet) => void;
  updateAutosave: SubmissionUpdate;
}) {
  useEffect(() => {
    updateAutosave(answers);
  }, [answers]); // eslint-disable-line react-hooks/exhaustive-deps
}
