import {
  DocumentReference,
  DocumentSnapshot,
  onSnapshot,
  Query,
  QuerySnapshot,
  SnapshotListenOptions,
} from "@firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";

export function useDocumentSnapshots<T>(
  refs: Array<DocumentReference<T>>,
  { includeMetadataChanges }: SnapshotListenOptions = {}
) {
  return useSnapshots<DocumentReference<T>, DocumentSnapshot<T>>(
    refs,
    useCallback((input, observer) => onSnapshot(input, { includeMetadataChanges }, observer), [includeMetadataChanges])
  );
}

export function useDocumentSnapshot<T>(ref: DocumentReference<T> | null, options: SnapshotListenOptions = {}) {
  const [snapshot] = useDocumentSnapshots(
    useMemo(() => (ref === null ? [] : [ref]), [ref]),
    options
  );
  return snapshot ?? null;
}

export function useSnapshotData<T>(snapshot: DocumentSnapshot<T> | null) {
  return useMemo(() => (snapshot ? snapshot.data() : null), [snapshot]);
}

export function useQuerySnapshots<T>(refs: Array<Query<T>>) {
  return useSnapshots<Query<T>, QuerySnapshot<T>>(refs, onSnapshot);
}

export function useQuerySnapshot<T>(ref: Query<T> | null) {
  const [snapshot] = useQuerySnapshots(useMemo(() => (ref === null ? [] : [ref]), [ref]));
  return snapshot ?? null;
}

function useSnapshots<TRef, TOutput>(
  refs: Array<TRef>,
  onSnapshot: (input: TRef, observer: (output: TOutput) => void) => () => void
) {
  const [snapshots, setSnapshots] = useState<Partial<Record<number, TOutput>>>({});

  useEffect(() => {
    let aborted = false;

    const unsubscribeCallbacks = refs.map((ref, i) =>
      onSnapshot(ref, (value) => {
        if (aborted) return;
        setSnapshots((snapshots) => ({ ...snapshots, [i]: value }));
      })
    );

    return () => {
      aborted = true;
      setSnapshots({});

      // Optimization: give a chance to re-subscribe to the same targets avoiding network calls.
      setTimeout(() => {
        unsubscribeCallbacks.forEach((cb) => {
          cb();
        });
      }, 10000);
    };
  }, [onSnapshot, refs]);

  return useMemo(() => refs.map((ref, i) => snapshots[i] ?? null), [refs, snapshots]);
}
