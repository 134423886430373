import { css } from "@emotion/css";
import { QuestionDefinition, QuestionReference } from "src/quiz";
import { BlockContentView } from "src/ui/BlockContentView";
import { QuestionInner } from "./QuestionInner";

export function Question({
  name,
  question,
  questionRef,
  editable,
  value,
  savedValue,
  onChange,
  showSolutions,
}: {
  name: string;
  question: QuestionDefinition;
  questionRef: QuestionReference;
  editable: boolean;
  value: string;
  savedValue: string;
  onChange: (value: string) => void;
  showSolutions: boolean;
}) {
  return (
    <>
      <div
        id={`question-${questionRef.name}`}
        className={css`
          position: relative;
          bottom: 12rem;
          height: 0;

          @media print {
            display: none;
          }
        `}
      />
      <BlockContentView value={question.prompt.body} />
      <div
        className={css`
          margin: 1rem 0;
          break-inside: avoid;
        `}
      >
        <div
          className={css`
            font-size: 0.75rem;
            padding: 0 0.5rem;
          `}
        >
          Domanda {questionRef.name}
        </div>
        <div
          className={css`
            padding: 0.5rem 0.5rem;
            border-radius: 0.5rem;

            @media screen {
              background-color: rgb(0 0 0 / 10%);
            }

            @media print {
              border: 8px solid rgb(0 0 0 / 10%);
            }
          `}
        >
          <QuestionInner
            name={name}
            question={question}
            questionRef={questionRef}
            editable={editable}
            value={value}
            savedValue={savedValue}
            onChange={onChange}
            showSolutions={showSolutions}
          />
        </div>
      </div>
    </>
  );
}
