import { css } from "@emotion/css";
import { Fragment } from "react";
import { ProblemPrompt, ProblemReference } from "src/quiz";
import { buildProblemSharedDataId } from "src/shared-data";
import { Question } from "src/ui/Question";
import { unreachable } from "src/util";

export function Problem({
  problemRef,
  sharedData,
  editable,
  answers,
  savedAnswers,
  onChange,
  showSolutions,
}: {
  problemRef: ProblemReference;
  sharedData: Record<string, string>;
  editable: boolean;
  answers: Array<string | null>;
  savedAnswers: Array<string | null>;
  onChange: (index: number, value: string) => void;
  showSolutions: boolean;
}) {
  const problemData = JSON.parse(sharedData[buildProblemSharedDataId(problemRef.id)] ?? unreachable()) as ProblemPrompt;

  return (
    <section
      className={css`
        margin: 0 0 4rem;

        @media print {
          margin: 0 0 2rem;
        }
      `}
    >
      <h4
        className={css`
          break-after: avoid;
        `}
      >
        Problema {problemRef.name}
      </h4>
      {problemRef.questions.map((questionRef, questionIndex) => (
        <Fragment key={questionIndex}>
          <Question
            name={`${problemRef.id}.${questionIndex}`}
            question={problemData.questions[questionIndex]}
            questionRef={questionRef}
            editable={editable}
            onChange={(value) => {
              onChange(questionIndex, value);
            }}
            value={answers[questionIndex] ?? unreachable()}
            savedValue={savedAnswers[questionIndex] ?? unreachable()}
            showSolutions={showSolutions}
          />
        </Fragment>
      ))}
    </section>
  );
}
