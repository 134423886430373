import { css, cx } from "@emotion/css";
import { FirebaseError } from "@firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "@firebase/auth";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { buildImplicitEmailAddress } from "src/common";
import { passphrasePattern } from "src/passphrase";
import { danger, primary, secondary } from "src/ui/colors";
import { useCurrentContestUser, useCurrentUser } from "src/ui/CurrentUserLoader";
import { unreachable } from "src/util";
import { useSite } from "./getSite";
import { autoFixPassphraseInput, normalizePassphraseInput, validatePassphrase } from "./passphrase-utils";

export function LoginForm() {
  const currentUser = useCurrentContestUser();
  const site = useSite();

  const [state, setState] = useState<
    { status: "pristine" } | { status: "loading" } | { status: "error"; error: unknown }
  >({ status: "pristine" });

  const { token, unsavedToken, setUnsavedToken, setToken } = useTokenState();

  const isDisabled = currentUser !== null;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useLayoutEffect(() => {
    const input = inputRef.current;
    if (!input) return;
    if (state.status !== "error") return;
    const { error } = state;
    if (error instanceof FirebaseError && error.code === "auth/user-not-found") {
      input.setCustomValidity(`Password errata.`);
    } else {
      console.error(error);
      input.setCustomValidity(`Errore durante il login.`);
    }
    input.reportValidity();
  }, [state]);

  useEffect(() => {
    if (token === null) return;
    if (validatePassphrase(token).isError) return;

    setState({ status: "loading" });
    signInWithEmailAndPassword(getAuth(), buildImplicitEmailAddress(token, site.name), token).then(
      () => {
        setState({ status: "pristine" });
      },
      (error) => {
        setState({ status: "error", error });
      }
    );
  }, [site.name, token]);

  return (
    <form
      className={css`
        display: flex;
        padding: 0.5rem 0.75rem;
        margin: 2rem 0;
        border-radius: 0.5rem;
        gap: 0.5rem;
        background-color: rgb(0 0 0 / 10%);
      `}
      onSubmit={(event) => {
        event.preventDefault();

        const input = event.currentTarget.elements.namedItem("token") as HTMLInputElement;

        normalizePassphraseInput(input);
        setUnsavedToken(input.value);

        const { message, isError } = validatePassphrase(input.value);

        if (isError) {
          input.setCustomValidity(message);
          input.reportValidity();

          return;
        }

        const token = input.value;
        if (token === null) unreachable();

        setToken(token);
      }}
    >
      <input
        ref={inputRef}
        className={css`
          font-size: 1.5rem;
          font-family: monospace;
          padding: 0.5rem 0.75rem;
        `}
        type={isDisabled ? "password" : "text"}
        disabled={isDisabled}
        name="token"
        defaultValue={token ?? unsavedToken ?? ""}
        placeholder={passphrasePattern}
        required
        onChange={(event) => {
          const input = event.currentTarget;
          if (event.nativeEvent instanceof InputEvent) autoFixPassphraseInput(input, event.nativeEvent);
          normalizePassphraseInput(input);
          setUnsavedToken(input.value);

          const { immediateMessage, isImmediateError } = validatePassphrase(input.value);

          setState({ status: "pristine" });
          if (isImmediateError) {
            input.setCustomValidity(immediateMessage);
            input.reportValidity();
          } else {
            input.setCustomValidity("");
          }
        }}
      />
      <button
        className={cx(
          css`
            color: white;
            border: none;
            padding: 0.5rem 0.75rem;
            border-radius: 0.5rem;

            &:hover:not([disabled]) {
              cursor: pointer;
              filter: brightness(90%);
            }
          `,
          isDisabled
            ? css`
                background-color: ${secondary};
                opacity: 0.4;
              `
            : css`
                background-color: ${primary};
              `
        )}
        disabled={isDisabled}
      >
        Log in
      </button>
      {currentUser && (
        <button
          type="button"
          onClick={() => {
            setToken(null);
            signOut(getAuth());
          }}
          className={cx(
            css`
              margin-left: auto;
              color: white;
              border: none;
              padding: 0.5rem 0.75rem;
              border-radius: 0.5rem;
              background-color: ${danger};

              &:hover:not([disabled]) {
                cursor: pointer;
                filter: brightness(90%);
              }
            `,
            "btn btn-danger"
          )}
        >
          Log out
        </button>
      )}
    </form>
  );
}

export function useTokenState() {
  const user = useCurrentUser();

  const currentUserToken = user?.email?.split("@")[0] ?? null;
  const automaticToken = new URLSearchParams(window.location.search).get("t") ?? null;

  const [unsavedToken, setUnsavedToken] = useState(() => {
    return automaticToken ?? sessionStorage.getItem("token");
  });

  const [token, setToken] = useState(() => {
    if (automaticToken) return automaticToken;

    try {
      const value = localStorage.getItem("token");
      return unsavedToken === null || value === unsavedToken ? value : currentUserToken;
    } catch {
      return currentUserToken;
    }
  });

  useEffect(() => {
    if (automaticToken) window.history.replaceState(undefined, "", "?");
  }, [automaticToken]);

  return {
    unsavedToken,
    setUnsavedToken: useCallback((value: string) => {
      setUnsavedToken(value || null);
      try {
        if (value === null) sessionStorage.removeItem("token");
        else sessionStorage.setItem("token", value);
      } catch {
        // No-op
      }
    }, []),
    token,
    setToken: useCallback((value: string | null) => {
      setToken(value);
      try {
        if (value === null) localStorage.removeItem("token");
        else localStorage.setItem("token", value);
      } catch {
        // No-op
      }
    }, []),
  };
}
