import { css, cx } from "@emotion/css";
import { ReactNode } from "react";
import { SubmissionState } from "src/ui/SubmissionState";
import { answerColorByStatus } from "./AnswerStatus";

export function QuizSummary({ submissionState, panel }: { submissionState: SubmissionState; panel?: ReactNode }) {
  const { diffToSubmitted: diff, submissionMetadata } = submissionState;

  return (
    <div
      className={css`
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;
        box-shadow: 0 0 0.25rem 0.25rem white;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-flow: row wrap;
          padding: 0.25rem 0;
          gap: 0 0.5rem;
          align-items: baseline;
        `}
      >
        <div
          className={css`
            flex: 0 0 auto;
            font-size: 0.75rem;
          `}
        >
          Domande:
        </div>
        <div
          className={css`
            flex: 1 1 auto;
            display: flex;
            flex-flow: column;
            gap: 0.125rem;
          `}
        >
          <div
            className={css`
              display: flex;
              flex-flow: row wrap;
              gap: 0.25rem;
            `}
          >
            {diff.questions.map(({ name, status }, i) => (
              <a
                key={i}
                className={cx(
                  css`
                    display: inline-block;
                    padding: 0 0.25rem;
                    min-width: 2rem;
                    text-align: center;
                    color: white;
                    font-weight: bold;
                    transition: background-color 150ms;
                  `,
                  css`
                    background-color: ${answerColorByStatus[status]};
                  `
                )}
                href={`#question-${name}`}
              >
                {name}
              </a>
            ))}
          </div>
          <div
            className={css`
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              font-size: 0.75rem;
              line-height: 120%;
            `}
          >
            <div>
              {diff.countByStatus.saved > 0 && (
                <span
                  className={css`
                    color: ${answerColorByStatus["saved"]};
                  `}
                >
                  {diff.countByStatus.saved} risposte salvate.
                </span>
              )}{" "}
              {diff.countByStatus.blank > 0 && (
                <span
                  className={css`
                    color: ${answerColorByStatus["blank"]};
                  `}
                >
                  {diff.countByStatus.blank} domande in bianco.
                </span>
              )}{" "}
              {diff.countByStatus.changed > 0 && (
                <span
                  className={css`
                    color: ${answerColorByStatus["changed"]};
                  `}
                >
                  {diff.countByStatus.changed} risposte da salvare.
                </span>
              )}{" "}
              {diff.countByStatus.deleted > 0 && (
                <span
                  className={css`
                    color: ${answerColorByStatus["deleted"]};
                  `}
                >
                  {diff.countByStatus.deleted} domande in bianco da salvare.
                </span>
              )}{" "}
              {submissionMetadata.hasPendingWrites && <>Salvataggio...</>}{" "}
            </div>
            {panel && <div>{panel}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
