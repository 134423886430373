import { passphraseAlphabet, passphraseParts, passphraseSeparator, passphraseSeparatorIndexes } from "src/passphrase";

export function autoFixPassphraseInput(input: HTMLInputElement | HTMLTextAreaElement, event: InputEvent) {
  if (!event.inputType.includes("insert")) return; // Ignore deletions and other changes
  if (input.selectionStart !== input.value.length) return; // Ignore changes in the middle

  fixPassphraseInput(input);

  input.setSelectionRange(input.value.length, input.value.length, "none");
}

export function fixPassphraseInput(input: HTMLInputElement | HTMLTextAreaElement) {
  const { value } = input;

  let fixStart = 0;
  let fixPart = 0;

  for (;;) {
    const index = value.substring(fixStart).indexOf(passphraseSeparator);

    if (fixPart === passphraseParts.length - 1) {
      if (index !== -1) return; // Stop if unexpected extra dash
      break;
    } else {
      if (index === -1) break; // Ok, possibly something to fix
      if (index !== passphraseParts[fixPart].length) return; // Stop if misplaced dash

      fixStart += index + 1;
      fixPart += 1;
    }
  }

  for (; fixPart < passphraseParts.length - 1; fixPart += 1) {
    const dashIndex = passphraseParts[fixPart].end;
    if (input.value.length < dashIndex) break; // Not long enough to insert next dash
    input.setRangeText(passphraseSeparator, dashIndex, dashIndex);
  }
}

export function normalizePassphraseInput(input: HTMLInputElement | HTMLTextAreaElement) {
  const lowerCased = input.value.toLowerCase();
  if (input.value !== lowerCased) {
    const { selectionStart, selectionEnd } = input;
    input.setRangeText(lowerCased, 0, lowerCased.length);
    input.setSelectionRange(selectionStart, selectionEnd);
  }
}

export function validatePassphrase(value: string) {
  const characters = Array.from(value).map((c, i) => ({ c, i }));
  const invalidCharacters = characters.filter(({ c }) => c !== passphraseSeparator && !passphraseAlphabet.includes(c));
  const otherCharacters = characters.filter(({ c }) => c !== passphraseSeparator).map(({ i }) => i);
  const separators = characters.filter(({ c }) => c === passphraseSeparator).map(({ i }) => i);

  const immediateErrors: string[] = [];
  const eventualErrors: string[] = [];

  const expectedOtherCharactersLength = passphraseParts.reduce((sum, x) => sum + x.length, 0);

  if (otherCharacters.length < expectedOtherCharactersLength) eventualErrors.push(`è troppo corta`);
  if (separators.length < passphraseSeparatorIndexes.length) eventualErrors.push(`manca un trattino`);
  if (otherCharacters.length > expectedOtherCharactersLength) immediateErrors.push(`è troppo lunga`);

  if (invalidCharacters.length > 0) {
    immediateErrors.push(`contiene caratteri non validi (${invalidCharacters.map(({ c }) => `'${c}'`).join(", ")})`);
  }

  if (separators.length > passphraseSeparatorIndexes.length) immediateErrors.push(`contiene un trattino in eccesso`);
  if (separators.some((i, j) => passphraseSeparatorIndexes[j] !== i)) {
    immediateErrors.push(`contiene un trattino in posizione errata`);
  }

  const isImmediateError = immediateErrors.length > 0;
  const immediateMessage = isImmediateError
    ? `La password inserita contiene degli errori: ${immediateErrors.join(", ")}.`
    : ``;

  const errors = [...eventualErrors, ...immediateErrors];
  const isError = errors.length > 0;
  const message = isError ? `La password inserita contiene degli errori: ${errors.join(", ")}.` : ``;

  return { isError, message, isImmediateError, immediateMessage };
}
