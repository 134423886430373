import { SubmissionAnswerSet } from "src/db";
import { QuizDefinition } from "src/quiz";
import { AnswerStatus } from "./AnswerStatus";

export function diffAnswers(
  quiz: QuizDefinition,
  answers: SubmissionAnswerSet,
  baseAnswers: SubmissionAnswerSet | null
) {
  const questions = quiz.sections
    .flatMap((section) => section.problems)
    .flatMap((problem) =>
      problem.questions.flatMap((question, questionIndex) => {
        const localAnswer = answers[problem.id][questionIndex];
        const savedAnswer = baseAnswers?.[problem.id][questionIndex] ?? "";

        const status: AnswerStatus =
          localAnswer === savedAnswer
            ? localAnswer === ""
              ? "blank"
              : "saved"
            : localAnswer === ""
            ? "deleted"
            : "changed";

        return {
          name: question.name,
          localAnswer,
          savedAnswer,
          status,
        };
      })
    );

  const countByStatus: Record<AnswerStatus, number> = {
    saved: questions.filter((x) => x.status === "saved").length,
    changed: questions.filter((x) => x.status === "changed").length,
    blank: questions.filter((x) => x.status === "blank").length,
    deleted: questions.filter((x) => x.status === "deleted").length,
  };

  const isPristine = countByStatus.changed === 0 && countByStatus.deleted === 0;
  const isBlank = isPristine && countByStatus.saved === 0;

  return { questions, countByStatus, isPristine, isBlank };
}

export type AnswerSetDiff = ReturnType<typeof diffAnswers>;
