import { danger, info, primary, secondary } from "src/ui/colors";

export type AnswerStatus = "blank" | "changed" | "saved" | "deleted";

export const answerColorByStatus: Record<AnswerStatus, string> = {
  saved: primary,
  changed: info,
  blank: secondary,
  deleted: danger,
};
