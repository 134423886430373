import { css } from "@emotion/css";
import { ReactNode } from "react";

export function RootLayout({ children }: { children: ReactNode }) {
  return (
    <div
      className={css`
        max-width: 60rem;
        margin: 0 auto;
        padding: 0 1rem 50vh;
      `}
    >
      {children}
    </div>
  );
}
