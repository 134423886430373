import { doc, DocumentReference } from "@firebase/firestore";
import { ReactNode, useMemo } from "react";
import { GroupData } from "src/db";
import { useDocumentSnapshot, useSnapshotData } from "src/firebase-util";
import { useSiteDoc } from "src/ui/getSite";
import { updateGroup } from "src/ui/updateGroup";

export type GroupDataUpdate = (data: Pick<GroupData, "startTime" | "autoStart">) => Promise<void>;

export function GroupDataLoader({
  groupId,
  children,
}: {
  groupId: string;
  children: (data: GroupData, update: GroupDataUpdate) => ReactNode;
}) {
  const siteDoc = useSiteDoc();
  const document = useMemo(() => doc(siteDoc, "groups", groupId) as DocumentReference<GroupData>, [groupId, siteDoc]);

  const data = useSnapshotData(useDocumentSnapshot(document));
  if (!data) return <>Loading...</>;

  return (
    <>
      {children(data, async (updates) => {
        updateGroup({ siteDoc, updates, group: data, origin: "proctor" });
      })}
    </>
  );
}
