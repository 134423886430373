export function mapEntries<T, U>(
  object: Record<string, T>,
  mapper: (entry: [string, T]) => [string, U]
): Record<string, U> {
  return Object.fromEntries(Object.entries(object).map((entry) => mapper(entry)));
}

export function mapKeys<T>(object: Record<string, T>, mapper: (key: string, value: T) => string) {
  return mapEntries(object, ([key, value]) => [mapper(key, value), value]);
}

export function mapValues<T, U>(object: Record<string, T>, mapper: (value: T, key: string) => U) {
  return mapEntries(object, ([key, value]) => [key, mapper(value, key)]);
}

export function unreachable(reason?: string): never {
  throw new Error(reason);
}

export function assert(condition: boolean, reason?: string) {
  if (!condition) unreachable(reason);
}

export function forgetKeyOrder<T>(object: Record<string, T>): Record<string, T> {
  return Object.fromEntries(
    Object.keys(object)
      .sort()
      .map((key) => [key, object[key]])
  );
}
