import { useMemo } from "react";
import { uidSeparatorCharacter } from "src/common";
import { Announcement } from "src/ui/Announcement";
import { BlockContentView } from "src/ui/BlockContentView";
import { GroupDataLoader } from "src/ui/GroupDataLoader";
import { GroupStartTimeDisplay } from "src/ui/GroupStartTimeDisplay";
import { HeaderDataLoader } from "src/ui/HeaderDataLoader";
import { loadQuiz } from "src/ui/loadQuiz";
import { parseConfigTime } from "src/ui/parseConfigTime";
import { ParticipantDataLoader } from "src/ui/ParticipantDataLoader";
import { ParticipantQuizView } from "src/ui/ParticipantQuizView";
import { SubmissionSource } from "src/ui/SubmissionStateLoader";
import { EnterContest } from "./EnterContest";
import { SubmissionStateFullLoader } from "./SubmissionStateFullLoader";

export function ParticipantView({ groupId, participantId }: { groupId: string; participantId: string }) {
  const compositeUserId = [groupId, participantId].join(uidSeparatorCharacter);

  const source = useMemo(
    (): SubmissionSource => ({ origin: "participant", groupId, participantId }),
    [groupId, participantId]
  );

  return (
    <div>
      <Announcement which="allParticipantsMessage" />
      <ParticipantDataLoader groupId={groupId} participantId={participantId}>
        {(participantData) => (
          <HeaderDataLoader participantData={participantData}>
            {(header) => (
              <>
                <h1>{participantData.info.displayName}</h1>
                <BlockContentView value={header} />
                <hr />
                <EnterContest
                  groupId={groupId}
                  participantId={participantId}
                  participantData={participantData}
                  whenStarted={(sharedData) => {
                    const quiz = loadQuiz(sharedData, groupId, participantId);
                    return (
                      <GroupDataLoader groupId={groupId}>
                        {(groupData) => (
                          <SubmissionStateFullLoader
                            compositeUserId={compositeUserId}
                            canSubmit={groupData.participantsCanSubmit ?? false}
                            quiz={quiz}
                            source={source}
                          >
                            {(submissionState) => (
                              <ParticipantQuizView
                                quiz={quiz}
                                sharedData={sharedData}
                                groupData={groupData}
                                submissionState={submissionState}
                              />
                            )}
                          </SubmissionStateFullLoader>
                        )}
                      </GroupDataLoader>
                    );
                  }}
                  whenWaiting={(groupData) =>
                    groupData.startTime ? (
                      <>
                        La gara inizierà alle <GroupStartTimeDisplay time={parseConfigTime(groupData.startTime)} />
                        {!groupData.autoStart && <> (orario previsto)</>}.
                      </>
                    ) : (
                      <>La gara non è ancora iniziata.</>
                    )
                  }
                ></EnterContest>
              </>
            )}
          </HeaderDataLoader>
        )}
      </ParticipantDataLoader>
    </div>
  );
}
