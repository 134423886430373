import { Fragment, ReactNode } from "react";
import { QuizDefinition, QuizSectionDefinition } from "src/quiz";

export function QuizProblemSetView({
  quiz,
  renderSection,
}: {
  quiz: QuizDefinition;
  renderSection: (section: QuizSectionDefinition) => ReactNode;
}) {
  return (
    <>
      {quiz.sections.map((section, i) => (
        <Fragment key={i}>{renderSection(section)}</Fragment>
      ))}
    </>
  );
}
