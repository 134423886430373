import { doc, DocumentReference } from "@firebase/firestore";
import { ReactNode, useMemo } from "react";
import { uidSeparatorCharacter } from "src/common";
import { EnvelopeCutter, ParticipantQuizData } from "src/db";
import { useDocumentSnapshot, useSnapshotData } from "src/firebase-util";
import { useSiteDoc } from "src/ui/getSite";

export function EnvelopeCutterLoader({
  groupId,
  participantId,
  children,
}: {
  groupId: string;
  participantId: string;
  children: (envelopeCutter: EnvelopeCutter) => ReactNode;
}) {
  const siteDoc = useSiteDoc();
  const document = useMemo(
    () =>
      doc(
        siteDoc,
        "quiz",
        [groupId, participantId].join(uidSeparatorCharacter)
      ) as DocumentReference<ParticipantQuizData>,
    [groupId, participantId, siteDoc]
  );
  const data = useSnapshotData(useDocumentSnapshot(document));

  if (data === null) return <>Fetching envelope cutter...</>;

  return <>{children(data.envelopeCutter)}</>;
}
