import { collection, CollectionReference, query, where } from "@firebase/firestore";
import { ReactNode, useMemo } from "react";
import { ParticipantData } from "src/db";
import { useQuerySnapshot } from "src/firebase-util";
import { useSiteDoc } from "src/ui/getSite";

export function GroupParticipantListLoader({
  groupId,
  children,
}: {
  groupId: string;
  children: (data: ParticipantData[]) => ReactNode;
}) {
  const siteDoc = useSiteDoc();
  const participantCollection = useMemo(
    () =>
      query(collection(siteDoc, "participants"), where("groupId", "==", groupId)) as CollectionReference<ParticipantData>,
    [groupId, siteDoc]
  );

  // Prefetch
  useQuerySnapshot(
    useMemo(() => query(collection(siteDoc, "quiz"), where("groupId", "==", groupId)), [groupId, siteDoc])
  );
  useQuerySnapshot(
    useMemo(() => query(collection(siteDoc, "submissions"), where("groupId", "==", groupId)), [groupId, siteDoc])
  );
  useQuerySnapshot(
    useMemo(() => query(collection(siteDoc, "autosave"), where("groupId", "==", groupId)), [groupId, siteDoc])
  );

  const snapshot = useQuerySnapshot(participantCollection);
  if (!snapshot) return <>Loading...</>;

  const data = snapshot.docs.map((doc) => doc.data());

  return <>{children(data)}</>;
}
