import { doc, DocumentReference } from "@firebase/firestore";
import { ReactNode, useMemo } from "react";
import { uidSeparatorCharacter } from "src/common";
import { ParticipantData } from "src/db";
import { useDocumentSnapshot, useSnapshotData } from "src/firebase-util";
import { useSiteDoc } from "src/ui/getSite";

export function ParticipantDataLoader({
  groupId,
  participantId,
  children,
}: {
  groupId: string;
  participantId: string;
  children: (data: ParticipantData) => ReactNode;
}) {
  const siteDoc = useSiteDoc();
  const document = useMemo(
    () =>
      doc(
        siteDoc,
        "participants",
        [groupId, participantId].join(uidSeparatorCharacter)
      ) as DocumentReference<ParticipantData>,
    [groupId, participantId, siteDoc]
  );

  const data = useSnapshotData(useDocumentSnapshot(document));

  if (data === null) return <>Loading participant data...</>;

  return <>{children(data)}</>;
}
