import { css } from "@emotion/css";
import { useMemo } from "react";
import { uidSeparatorCharacter } from "src/common";
import { GroupData } from "src/db";
import { Announcement } from "src/ui/Announcement";
import { useSite } from "src/ui/getSite";
import { GroupConfig } from "src/ui/GroupConfig";
import { GroupDataUpdate } from "src/ui/GroupDataLoader";
import { GroupParticipantListLoader } from "src/ui/GroupParticipantListLoader";
import { ProctorSubmitForParticipantButton } from "src/ui/ProctorSubmitForParticipantButton";
import { SubmissionSource, SubmissionStateLoader } from "src/ui/SubmissionStateLoader";
import { SubmissionSummary } from "src/ui/SubmissionSummary";
import { DownloadLink } from "./DownloadLink";
import { GroupStatus } from "./GroupStatus";

export function ProctorView({
  groupId,
  groupData,
  groupUpdate,
}: {
  groupId: string;
  groupData: GroupData;
  groupUpdate: GroupDataUpdate;
}) {
  const site = useSite();
  const source = useMemo((): SubmissionSource => ({ origin: "proctor", groupId }), [groupId]);

  return (
    <div>
      <h1>{groupData.info.displayName}</h1>

      <Announcement which="allProctorsMessage" />

      <ul
        className={css`
          li {
            margin: 0.5rem 0;
          }
        `}
      >
        <li>
          <DownloadLink path={`site/${site.name}/group/${groupId}/all-quizzes.pdf`}>
            Scarica PDF con tutti i quiz
          </DownloadLink>
        </li>
        <li>
          <GroupStatus groupData={groupData} />
          <GroupConfig groupData={groupData} groupUpdate={groupUpdate} />
        </li>
      </ul>

      <GroupParticipantListLoader groupId={groupId}>
        {(participants) => (
          <div>
            <h2>Partecipanti ({participants.length})</h2>
            <p>
              Nota: questa lista non è aggiornata in tempo reale. Sarà aggiornata con gli iscritti la sera prima della
              gara.
            </p>
            <div>
              {participants.map((participant, i) => {
                const participantId = participant.participantId;

                return (
                  <div
                    key={i}
                    className={css`
                      display: flex;
                      gap: 1rem;
                      padding: 0.5rem;

                      &:nth-child(2n) {
                        background-color: rgb(0 0 0 / 10%);
                      }

                      &:nth-child(2n + 1) {
                        background-color: rgb(0 0 0 / 5%);
                      }
                    `}
                  >
                    <div
                      className={css`
                        flex: 1 1 auto;
                      `}
                    >
                      {participant.participantId} {participant.info.displayName}
                    </div>
                    <div
                      className={css`
                        flex: 0 1 auto;
                      `}
                    >
                      <SubmissionStateLoader
                        collectionName="submissions"
                        compositeUserId={[groupId, participantId].join(uidSeparatorCharacter)}
                        source={source}
                      >
                        {(submission) => <SubmissionSummary submission={submission} />}
                      </SubmissionStateLoader>
                    </div>
                    <DownloadLink path={`site/${site.name}/group/${groupId}/participant/${participantId}/quiz.pdf`}>
                      Scarica quiz
                    </DownloadLink>
                    <div
                      className={css`
                        flex: 0 0 auto;
                      `}
                    >
                      <ProctorSubmitForParticipantButton participant={participant} group={groupData} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </GroupParticipantListLoader>
    </div>
  );
}
