import { css } from "@emotion/css";
import { Fragment, ReactNode } from "react";
import { ProblemReference, QuizSectionDefinition } from "src/quiz";
import { BlockContentView } from "src/ui/BlockContentView";

export function QuizSection({
  section,
  renderProblem,
}: {
  section: QuizSectionDefinition;
  renderProblem: (problem: ProblemReference) => ReactNode;
}) {
  return (
    <section
      className={css`
        margin: 0 0 6rem;

        @media print {
          margin: 0 0 2rem;
        }
      `}
    >
      <hr
        className={css`
          @media print {
            display: none;
          }
        `}
      />
      <div
        className={css`
          break-after: avoid;

          @media print {
            text-align: center;
            margin: 2rem 0 0;
            font-size: 1.5rem;
          }
        `}
      >
        <BlockContentView value={section.header} />
      </div>
      <div
        className={css`
          @media print {
            columns: 2;
            column-gap: 1.5cm;
            column-rule: solid 1pt black;
            column-fill: auto;
            orphans: 3;
            widows: 3;
          }
        `}
      >
        {section.problems.map((problem, i) => (
          <Fragment key={i}>{renderProblem(problem)}</Fragment>
        ))}
      </div>
    </section>
  );
}
