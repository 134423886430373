import { SnapshotMetadata } from "@firebase/firestore";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { SubmissionAnswerSet, SubmissionData } from "src/db";
import { QuizDefinition } from "src/quiz";
import { SubmissionUpdate } from "src/ui/SubmissionStateLoader";
import { createAllBlankAnswerSet } from "./answer-utils";
import { AnswerSetDiff, diffAnswers } from "./diffAnswers";
import { usePromptBeforeUnload } from "./usePromptBeforeUnload";
import { useSyncAutoSave } from "./useSyncAutoSave";

export interface SubmissionState {
  diffToSubmitted: AnswerSetDiff;
  submissionMetadata: SnapshotMetadata;
  updateSubmission: SubmissionUpdate;
  answers: SubmissionAnswerSet;
  submittedAnswers: SubmissionAnswerSet | null;
  setAnswers: Dispatch<SetStateAction<SubmissionAnswerSet>>;
  status: SubmissionStatus;
  canSubmit: boolean;
}

export type SubmissionStatus = "blank" | "pristine" | "dirty" | "pending";

export function SubmissionStateManager({
  children,
  quiz,
  submission,
  updateSubmission,
  submissionMetadata,
  autosave,
  updateAutosave,
  canSubmit,
}: {
  quiz: QuizDefinition;
  children: (state: SubmissionState) => ReactNode;
  submission: SubmissionData;
  updateSubmission: SubmissionUpdate;
  submissionMetadata: SnapshotMetadata;
  autosave: SubmissionData;
  updateAutosave: SubmissionUpdate;
  canSubmit: boolean;
}) {
  const submittedAnswers = submission?.answers ?? null;
  const autosavedAnswers = autosave?.answers ?? null;

  const allBlankAnswers = createAllBlankAnswerSet(quiz);
  const [answers, setAnswers] = useState(autosavedAnswers ?? submittedAnswers ?? allBlankAnswers);

  const diffToSubmitted = diffAnswers(quiz, answers, submittedAnswers);

  useSyncAutoSave({ quiz, answers, autosavedAnswers, setAnswers, updateAutosave });
  usePromptBeforeUnload(!diffToSubmitted.isPristine);

  const status: SubmissionStatus = diffToSubmitted.isBlank
    ? "blank"
    : diffToSubmitted.isPristine
    ? submissionMetadata.hasPendingWrites
      ? "pending"
      : "pristine"
    : "dirty";

  return (
    <>
      {children({
        answers,
        canSubmit,
        diffToSubmitted,
        setAnswers,
        status,
        submissionMetadata,
        submittedAnswers,
        updateSubmission,
      })}
    </>
  );
}
