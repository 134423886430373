import { css } from "@emotion/css";
import { gray500 } from "src/ui/colors";


export const buttonCss = css`
  color: white;
  border: none;
  min-width: 12rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(127 127 127 / 30%);
  box-shadow: 0 0 0.5rem 0 rgb(127 127 127 / 15%);
  transition: background-color 150ms;

  &:hover:not([disabled]) {
    cursor: pointer;
    filter: brightness(90%);
  }

  &[disabled] {
    background-color: ${gray500};
  }
`;
