import { css } from "@emotion/css";
import { OpenQuestionDefinition, OpenQuestionReference, OpenQuestionSolution } from "src/quiz";
import { Solution } from "src/ui/Solution";

export function OpenQuestion({
  name,
  editable,
  value,
  savedValue,
  onChange,
  solution,
}: {
  name: string;
  question: OpenQuestionDefinition;
  questionRef: OpenQuestionReference;
  editable: boolean;
  value: string;
  savedValue: string;
  onChange: (value: string) => void;
  solution: OpenQuestionSolution | null;
}) {
  return (
    <div>
      <div
        className={css`
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: flex-end;
          text-align: right;
          gap: 0 0.5rem;
        `}
      >
        <label
          className={css`
            flex: 1 0 auto;
            font-size: 0.875rem;
          `}
          htmlFor={`input-${name}`}
        >
          La tua risposta:{" "}
        </label>
        <input
          id={`input-${name}`}
          type="text"
          name={name}
          disabled={!editable}
          value={value}
          onChange={(event) => {
            const input = event.currentTarget;
            input.setCustomValidity("");
            if (!input.checkValidity()) {
              input.setCustomValidity(`Deve essere un numero intero.`);
              input.reportValidity();
            }
            onChange(input.value);
          }}
          pattern="0|-?[1-9][0-9]*"
          className={css`
            flex: 1 1 16rem;
            max-width: 16rem;
            font-size: 1.5rem;
            padding: 0.25rem 0.5rem;
          `}
        />
      </div>
      <div
        className={css`
          min-height: 3rem;
        `}
      >
        <div
          className={css`
            font-size: 0.75rem;
            line-height: 1rem;
            color: rgb(0 0 0 / 60%);
            text-align: right;
          `}
        >
          {savedValue !== value && savedValue !== "" && (
            <>
              Risposta precedente: <strong>{savedValue}</strong>
            </>
          )}
          {solution && (
            <>
              Risposta corretta: <strong>{solution.correctAnswer}</strong>
            </>
          )}
        </div>
        {solution && <Solution solution={solution} />}
      </div>
    </div>
  );
}
