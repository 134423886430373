import { GroupData, ParticipantData } from "src/db";
import { Decrypter } from "src/ui/Decrypter";
import { EncryptedDataFetcher } from "src/ui/EncryptedDataFetcher";
import { EnvelopeCutterLoader } from "src/ui/EnvelopeCutterLoader";
import { GroupDataLoader } from "src/ui/GroupDataLoader";

export function EnterContest({
  groupId,
  participantId,
  participantData,
  whenStarted,
  whenWaiting,
}: {
  groupId: string;
  participantId: string;
  participantData: ParticipantData;
  whenStarted: (sharedData: Record<string, string>, groupData: GroupData) => JSX.Element;
  whenWaiting: (groupData: GroupData) => JSX.Element;
}) {
  return (
    <EncryptedDataFetcher participantData={participantData}>
      {(encryptedData) => (
        <GroupDataLoader groupId={groupId}>
          {(groupData) => {
            if (groupData.participantsCanReadQuiz) {
              return (
                <EnvelopeCutterLoader groupId={groupId} participantId={participantId}>
                  {(envelopeCutter) => (
                    <Decrypter encryptedData={encryptedData} envelopeCutter={envelopeCutter}>
                      {(sharedData) => whenStarted(sharedData, groupData)}
                    </Decrypter>
                  )}
                </EnvelopeCutterLoader>
              );
            }

            return whenWaiting(groupData);
          }}
        </GroupDataLoader>
      )}
    </EncryptedDataFetcher>
  );
}
