import { css } from "@emotion/css";
import { ChangeEvent, Fragment } from "react";
import {
  MultipleChoiceQuestionDefinition,
  MultipleChoiceQuestionReference,
  MultipleChoiceQuestionSolution,
} from "src/quiz";
import { answerColorByStatus } from "src/ui/AnswerStatus";
import { Solution } from "src/ui/Solution";
import { InlineBlockContentView } from "./BlockContentView";
import { MultipleChoiceOptionLetter } from "./MultipleChoiceOptionLetter";

export function MultipleChoiceQuestion({
  name,
  question,
  questionRef,
  editable,
  value,
  savedValue,
  onChange,
  solution,
}: {
  name: string;
  question: MultipleChoiceQuestionDefinition;
  questionRef: MultipleChoiceQuestionReference;
  editable: boolean;
  value: string;
  savedValue: string;
  onChange: (value: string) => void;
  solution: MultipleChoiceQuestionSolution | null;
}) {
  const onRadioChange = (event: ChangeEvent<HTMLInputElement>) => onChange(event.currentTarget.value);

  return (
    <div>
      <ul
        className={css`
          margin: 0;
          padding: 0 0.25rem;
        `}
      >
        {questionRef.options.map((optionRef, i) => (
          <Fragment key={i}>
            <li
              className={css`
                display: flex;
                list-style: none;
                align-items: baseline;
                gap: 0.5rem;
                margin: 0.5rem 0;
              `}
            >
              <div
                className={css`
                  display: flex;
                  flex: 0 0 auto;
                  align-items: baseline;
                  gap: 0.5rem;
                `}
              >
                <input
                  name={name}
                  id={`option-${name}-${optionRef}`}
                  value={optionRef}
                  type="radio"
                  disabled={!editable}
                  checked={value === optionRef}
                  className={css`
                    height: auto;
                    width: 1.25rem;
                    margin: 0;
                    cursor: pointer;
                    align-self: stretch;
                  `}
                  onChange={onRadioChange}
                />
                <label
                  className={css`
                    flex: 0 0 auto;
                    padding: 0.25rem 0;
                    padding-left: 2.5rem;
                    margin: -0.25rem 0;
                    margin-left: -2.5rem;
                    cursor: pointer;
                    font-size: 1.25rem;
                    font-family: monospace;
                  `}
                  htmlFor={`option-${name}-${optionRef}`}
                >
                  (<MultipleChoiceOptionLetter index={i} />)
                </label>
              </div>
              <InlineBlockContentView value={question.prompt.options[optionRef].body} />
            </li>
          </Fragment>
        ))}
      </ul>
      <div
        className={css`
          display: flex;
          flex-flow: column;
          min-height: 3.25rem;

          @media print {
            display: none;
          }
        `}
      >
        <div
          className={css`
            font-size: 0.75rem;
            line-height: 1rem;
            margin: 0.25rem 0;
            padding: 0 0.25rem;
            color: rgb(0 0 0 / 60%);
          `}
        >
          {!solution && savedValue !== value && savedValue !== "" && (
            <>
              Risposta precedente:{" "}
              <strong>
                <MultipleChoiceOptionLetter index={questionRef.options.indexOf(savedValue)} />
              </strong>
            </>
          )}
          {solution && (
            <>
              Risposta corretta:{" "}
              <MultipleChoiceOptionLetter index={questionRef.options.indexOf(solution.correctOptionKey)} />
            </>
          )}
        </div>
        {!solution && (
          <div
            className={css`
              display: flex;
              align-items: baseline;
              gap: 0.5rem;
              margin: auto 0 0;
              padding: 0 0.25rem;
            `}
          >
            <input
              name={name}
              id={`blank-${name}`}
              value=""
              type="radio"
              disabled={!editable}
              checked={value === ""}
              onChange={onRadioChange}
              className={css`
                margin: 0.25rem 0;
                width: 0.875rem;
                height: 0.875rem;
              `}
            />
            <label
              htmlFor={`blank-${name}`}
              className={css`
                color: ${answerColorByStatus.blank};
              `}
            >
              Lascia in bianco
            </label>
          </div>
        )}
        {solution && <Solution solution={solution} />}
      </div>
    </div>
  );
}
